<template>
  <th class="header user-header resource-th">
    <div class="flex justify-content-between align-items-center">
      <div class="col-3 align-self-center flex justify-content-center">
        <Avatar
          :image="user.profile_picture"
          :label="!user.profile_picture ? getIntials(user) : null"
          :style="{
            'background-color': !user.profile_picture
              ? getColorFromName(user)
              : 'none',
            color: '#ffffff',
          }"
          size="large"
          shape="circle"
        />
      </div>
      <div
        class="col cursor-pointer flex flex-column justify-content-start"
        @click="expandToggle"
      >
        <div class="flex align-items-start font-weight-normal">
          {{ user.name }}
        </div>
        <div
          v-if="workhours"
          class="font-light flex align-items-start font-smaller"
        >
          {{ workhours }} hrs / week
        </div>
      </div>

      <div
        v-if="user.tasks.length > 0"
        class="align-self-center col-2 cursor-pointer"
        @click="expandToggle"
      >
        <i
          class="las la-hat-cowboy icon-hover hover-scale"
          :class="{ 'sort-animation': isRefreshing }"
          @click.stop="sortUser"
        ></i>
        <i
          v-if="expand"
          class="las la-minus"
        />
        <i
          v-else
          class="las la-plus"
        />
      </div>
    </div>
  </th>
</template>

<script>
export default {
  data() {
    return {
      isRefreshing: false,
    };
  },
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    expand() {
      return !this.$store.getters.taskScheduleSettings.userCollapse.includes(
        this.user.uid
      );
    },
    workhours() {
      if (this.user.working_hours) {
        var ret = 0;
        for (var d in this.user.working_hours) {
          var element = this.user.working_hours[d];
          if (element.on) {
            var hoursMinutes = element.hours.split(":");
            var hours = parseInt(hoursMinutes[0], 10);
            var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
            ret += hours + minutes / 60;
          }
        }
        return ret;
      } else {
        return null;
      }
    },
  },
  methods: {
    expandToggle() {
      this.$emit("expandToggle");
    },
    sortUser() {
      this.isRefreshing = true;

      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/schedule/waterfall?user=" +
            this.user.uid
        )
        .then((response) => {
          this.$emit("refreshUser", this.user.uid);
        });
      setTimeout(() => {
        this.isRefreshing = false;
      }, 500);
    },
  },
};
</script>

<style>
.user-header {
  background-color: #f8fafb;
  vertical-align: middle;
  border-bottom: 1px solid rgb(232, 236, 239);
  border-right: 4px solid rgb(232, 236, 239);
}

.icon-hover:hover {
  background: rgba(100, 116, 139, 0.04);
  border-color: transparent;
  color: #64748b;
  border-radius: 100%;
}
</style>