<template>
  <div>
    <div v-if="today !== null && !isLoading">
      <div>
        <div class="tasks-schedule card">
          <simplebar
            ref="tableScroll"
            class="table-scroll"
            style="height:100%"
          >
            <table id="main-table">
              <thead
                class="resource-thead"
                style="border-top: 1px solid rgb(232, 236, 239)"
              >
                <th
                  class="col header department-header resource-th"
                  style="
                    border-right: 4px solid rgb(232, 236, 239);
                    min-width: 300px;
                  "
                />

                <th
                  :colspan="monthHeaders[index] ? '3' : '1'"
                  rowspan="2"
                  :style="monthHeaders[index] ? 'z-index:3;font-size:12px' : ''"
                  class="col pb-0 pt-0 font-light month-header"
                  v-for="(i, index) in days"
                  :key="index"
                >
                  <p
                    style="color: #98a9bc"
                    class="font-light"
                  >
                    <span v-if="monthHeaders[index]">
                      {{ monthHeaders[index].m }}
                      {{ monthHeaders[index].y }}</span>
                  </p>
                </th>
              </thead>
              <thead class="resource-thead-days">
                <th
                  ref="departmentHeader"
                  class="header  resource-th"
                  style="border-right: 4px solid rgb(232, 236, 239); z-index: 9;background-color: white"
                >
                  <div class="pl-2 flex justify-content-between align-items-center text-16 font-normal">
                    My Tasks
                  </div>
                </th>

                <!-- <td style="border-top: 3px solid rgb(232, 236, 239)"></td> -->
                <th
                  class="col text-center p-2 date-cell department-header"
                  :style="
                    index === today
                      ? 'border-left-style: solid;  border-left-color: #4D7CFE;border-left-width:0.15rem'
                      : ''
                  "
                  v-for="(i, index) in days"
                  :key="index"
                >

                  <p
                    style="color: #778ca2; font-size: x-small"
                    class="font-light"
                  >
                    {{ i.day }}
                  </p>
                  <p
                    class="font-light"
                    style="color: #778ca2; font-size: small"
                  >
                    {{ i.date }}
                  </p>
                </th>
              </thead>

              <MyScheduleTableBody
                v-if="todayTasks(filteredTasks).length > 0 || searchTerm?.length > 0"
                :user="user"
                :tasks="todayTasks(filteredTasks)"
                :days="days"
                :today="today"
                :forToday="true"
                @update:search="searchTerm = $event"
                :getCellClass="getCellClass"
                :prevDay="prevDay"
                :nextDay="nextDay"
                :isWorkDay="isWorkDay"
              />

            </table>

            <div
              v-if="!todayTasks(filteredTasks).length > 0"
              class="text-center mt-2"
              style="padding: 30px 80px;background: #f8fafb; border-radius: 100px;"
            >

              {{ searchTerm.length > 0 ? 'No tasks found' :'No tasks scheduled for today' }}
            </div>
          </simplebar>

          <TabView
            class="col-12 p-tab-show-panel sticky-tabs p-0 mt-2 no-padding-tab-panel"
            :activeIndex.sync="activeTab"
          >
            <TabPanel header="Upcoming">
              <simplebar
                ref="tableScroll"
                class="table-scroll"
                style="height:100%"
              >

                <table
                  id="main-table"
                  v-if="notTodayTasks(filteredTasks)?.length > 0"
                >
                  <MyScheduleTableBody
                    :user="user"
                    :tasks="notTodayTasks(filteredTasks)"
                    :days="days"
                    :today="today"
                    :forToday="false"
                    :getCellClass="getCellClass"
                    :prevDay="prevDay"
                    :nextDay="nextDay"
                    :isWorkDay="isWorkDay"
                    v-if="user && filteredTasks && filteredTasks.length > 0"
                  />

                </table>

                <div
                  v-else
                  class="text-center mt-2"
                  style="padding: 30px 80px;background: #f8fafb; border-radius: 100px;"
                >

                  No tasks
                </div>
              </simplebar>
            </TabPanel>
            <TabPanel header="Completed">
              <simplebar
                ref="tableScroll"
                class="table-scroll"
                style="height:100%"
              >
                <table
                  id="main-table"
                  v-if="isCompletedTasks(filteredTasks)?.length > 0"
                >
                  <MyScheduleTableBody
                    :user="user"
                    :tasks="isCompletedTasks(filteredTasks)"
                    :days="days"
                    :today="today"
                    :forToday="false"
                    :getCellClass="getCellClass"
                    :prevDay="prevDay"
                    :nextDay="nextDay"
                    :isWorkDay="isWorkDay"
                    v-if="user && filteredTasks && filteredTasks.length > 0"
                  />
                </table>

                <div
                  v-else
                  class="text-center mt-2"
                  style="padding: 30px 80px;background: #f8fafb; border-radius: 100px;"
                >

                  No completed tasks
                </div>
              </simplebar>
            </TabPanel>
          </TabView>

        </div>
      </div>
    </div>
    <TaskScheduleLoading v-else></TaskScheduleLoading>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import { fetchUserTasks, state } from "../../../services/data_service";
import MyScheduleTableBody from "./MyScheduleTableBody.vue";
import TaskScheduleLoading from "../../TaskSchedule/views/TaskScheduleLoading.vue";

export default {
  props: {
    user_uid: {
      type: String,
      default: null,
    },
  },
  components: {
    simplebar,
    MyScheduleTableBody,
    TaskScheduleLoading,
  },
  data() {
    return {
      activeTab: 0,
      days: null,
      isLoading: false,
      isRefreshing: false,
      previousDays: 0,
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: ["S", "M", "T", "W", "T", "F", "S"],
      dayAbbreviations: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      today: null,
      minDate: null, // Initialize minDate
      maxDate: null, // Initialize maxDate
      yesterday: null, // Initialize yesterday
      firstDate: null,
      lastDate: null,
      monthHeaders: {},
      loadingColor: "",
      isOverrun: false,
      projectManagers: [],
      dayKeys: null,
      searchTerm: "",
    };
  },
  created() {
    // Convert days object keys to an array for efficient access
    this.dayKeys = Object.keys(this.days || {});
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    await this.loadData(true, -1);
  },
  computed: {
    userTasks() {
      return state.userTasks;
    },
    user() {
      return this.userTasks ? this.userTasks[0] : null;
    },
    filteredTasks() {
      return this.user?.tasks?.filter((task) => {
        return (
          task.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          task.client_name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          task.task_type_name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          task.job.title.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  watch: {
    days(newDays) {
      // Update dayKeys when days changes (e.g., when data is reloaded)
      this.dayKeys = Object.keys(newDays || {});
    },
  },
  methods: {
    async loadData(mustLoad = false, prevDays = 0) {
      await this.getData(mustLoad, prevDays);
    },
    todayTasks(tasks) {
      return tasks.filter((task) => {
        return task.resource_plan.some((plan) => {
          return plan.date === this.today;
        });
      });
    },
    notTodayTasks(tasks) {
      return tasks.filter((task) => {
        return !task.resource_plan.some((plan) => {
          return plan.date === this.today;
        });
      });
    },
    isCompletedTasks(tasks) {
      return tasks.filter((task) => {
        return task.is_complete == 1;
      });
    },
    isWorkDay(day) {
      return this.user?.workingDays?.[this.days[day].dayAbbr] ?? false;
    },
    async getData(mustLoad = true, previousDays = -1) {
      this.isLoading = mustLoad;

      if (this.isLoading) {
        state.userTasks = null;
      }

      var date_from = new Date().addDays(this.previousDays + previousDays);
      var date_to = new Date(date_from).addDays(28);

      date_from = date_from.toISOString().split("T")[0];
      date_to = date_to.toISOString().split("T")[0];

      await fetchUserTasks(null, false, date_from, date_to, this.user_uid);

      this.processData(this.userTasks, previousDays, date_to);

      this.isLoading = false;
      this.isRefreshing = false;
    },
    calculateHeight(plannedHours, dailyHours) {
      const totalHeight = 42;
      return (plannedHours / dailyHours) * totalHeight;
    },
    findMaxMinDate(user) {
      if (!user || !user.tasks) return;

      user.tasks.forEach((t) => {
        if (t.date_due) {
          if (t.date_due.date > this.maxDate || !this.maxDate) {
            this.maxDate = t.date_due.date;
          }
        }
        if (t.date_start?.date < this.minDate || !this.minDate) {
          this.minDate = t.date_start?.date;
        }
      });
    },
    getDates(startDate, stopDate) {
      const dateArray = {};
      let currentDate = startDate;
      const today = new Date();

      this.yesterday = this.buildShortDate(today.addDays(-1));
      this.today = this.buildShortDate(today);

      while (currentDate <= stopDate.addDays(1)) {
        const d = new Date(currentDate);
        const shortDate = this.buildShortDate(d);
        const newDate = {
          date: d.getDate(),
          month: d.getMonth(),
          year: d.getFullYear(),
          day: this.dayNames[d.getDay()],
          dayAbbr: this.dayAbbreviations[d.getDay()],
        };
        dateArray[shortDate] = newDate;
        currentDate = currentDate.addDays(1);
      }

      return dateArray;
    },
    processData(users, previousDays, date_to) {
      this.projectManagers = []; // Clear the project managers array

      if (users && users.length > 0) {
        const user = users[0]; // Get the first user
        const preprocessedUser = this.preprocessUser(user); // Only preprocess one user
        state.userTasks = [preprocessedUser]; // Overwrite the state with the processed user data

        this.findMaxMinDate(preprocessedUser); // Pass the single user data to function
      } else {
        state.userTasks = null;
        this.minDate = null;
        this.maxDate = null;
      }
      this.previousDays += previousDays;
      this.maxDate = new Date(date_to);

      this.days = this.getDates(
        new Date().addDays(this.previousDays),
        new Date(this.maxDate)
      );

      this.firstDate = Object.keys(this.days)[0];
      this.lastDate = Object.keys(this.days)[Object.keys(this.days).length - 1];
      this.dayKeys = Object.keys(this.days);

      const monthHeaders = {};
      let c = 0;

      Object.keys(this.days).forEach((key) => {
        if (this.days[key].date === 1 || key === this.firstDate) {
          const s = this.buildShortDate(new Date(key).addDays(c * -2));
          monthHeaders[s] = {
            d: s,
            m: this.monthNames[this.days[key].month],
            y: this.days[key].year,
          };
          c++;
        }
      });

      this.monthHeaders = monthHeaders;
      this.prevLoading = false;
      this.isLoading = false;
    },
    preprocessUser(user) {
      const processedUser = { ...user };

      // Precompute working hours and workingDays for each day
      processedUser.workingHoursByDay = {};
      processedUser.workingDays = {};
      if (user.working_hours) {
        // Add a check here
        Object.entries(user.working_hours || {}).forEach(([day, hours]) => {
          processedUser.workingDays[day] = hours.on;
          if (hours.on) {
            const [h, m] = hours.hours.split(":");
            processedUser.workingHoursByDay[day] = {
              on: true,
              minutes: +h * 60 + +m,
            };
          } else {
            processedUser.workingHoursByDay[day] = { on: false, minutes: 0 };
          }
        });
      }

      // Precompute task data
      processedUser.taskData = {};
      if (user.tasks) {
        // Add a check here
        user.tasks.forEach((task) => {
          if (task.resource_plan) {
            // And here
            task.resource_plan.forEach((plan) => {
              if (!processedUser.taskData[plan.date]) {
                processedUser.taskData[plan.date] = { total: 0, captured: 0 };
              }
              processedUser.taskData[plan.date].total +=
                parseFloat(plan.minutes) || 0;
              processedUser.taskData[plan.date].captured +=
                parseFloat(plan.minutes_worked) || 0;
            });
          }
        });
      }
      // Precompute over hours
      processedUser.overHours = {};
      Object.entries(processedUser.taskData).forEach(([date, data]) => {
        const dayOfWeek = new Date(date).getDay();
        const dayAbbr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
          dayOfWeek
        ];
        const workingHours =
          processedUser.workingHoursByDay[dayAbbr]?.minutes || 0;
        processedUser.overHours[date] = Math.max(0, data.total - workingHours);
      });

      return processedUser;
    },
    getCellClass(index, task, today) {
      let style = [];

      if (index === today) style.push("today");
      if (index === this.yesterday) style.push("yesterday");
      // if (index === task.date_due?.date) style.push("cell-due-date");

      if (task.resource_plan) {
        const c = task.resource_plan.find((a) => a.date === index);

        if (c) {
          style.push(
            index < today
              ? "past-schedule"
              : this.isOverrun
              ? "cell-overun"
              : c.time_worked && c.time_worked != "00:00"
              ? "time-spent"
              : "cell-active-half"
          );
          style.push(c.locked !== 1 ? "" : "cursor-default");
        } else {
          style.push(this.isWorkDay(index) ? "cell-blank" : "cell-sleep");
          // Removed this.isTop and this.isBottom because they were not defined
          // if (this.isTop)
          //   style.push("cell-sleep-top-left cell-sleep-top-right");
          // if (this.isBottom)
          //   style.push("cell-sleep-bottom-left cell-sleep-bottom-right");
        }
      }

      return style.join(" ");
    },
    buildShortDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    secToHM(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);

      var hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? "m" : "m") : "";
      return hDisplay + mDisplay;
    },
    prevDay(index) {
      const dayKeys = Object.keys(this.days);
      const currentIndex = dayKeys.indexOf(index);

      if (currentIndex > 0) {
        return dayKeys[currentIndex - 1];
      }
      return null;
    },

    nextDay(index) {
      const dayKeys = Object.keys(this.days);
      const currentIndex = dayKeys.indexOf(index);

      if (currentIndex < dayKeys.length - 1) {
        return dayKeys[currentIndex + 1];
      }
      return null;
    },
  },
};
</script>

<style>
.cell-active-half {
  border-radius: 0.4rem;
  border-color: rgb(242, 244, 246);
  color: #6f8092;
  padding-top: 0 !important;
}

.past-schedule {
  color: #b9c5d0 !important;
  border-color: rgb(242, 244, 246);
}

.scheduled-cell {
  position: absolute;
  bottom: 0;
  left: -1px;
  width: calc(
    100% + 2px
  ); /* Adjust width to include border. 2px is a rough estimate */
  height: 30%;
  background-color: #6dd230;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.table-scroll {
  position: relative;

  z-index: 1;
  margin: auto;
  overflow: auto;
  /* overflow-x: hidden; */
  height: calc(100vh - 206px);
  padding-bottom: 2rem;
}
.table-scroll table {
  /* min-width: 1280px; */
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll .resource-thead th {
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  z-index: 2;
  top: 0;
}

.table-scroll .resource-thead-days th {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 18px;
}

.table-scroll .resource-thead-user th {
  border-top: 3px solid #f2f4f6;
  position: -webkit-sticky;
  background-color: #f2f4f6;
  position: sticky;
  z-index: 2;
  top: 80px;
}

.no-padding-tab-panel > .p-tabview-panels {
  padding: 0 !important;
}

.no-padding-tab-panel
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav {
  border: none;
}
</style>