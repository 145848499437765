<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="agencydesk-spacer" />

      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-6">
          <div class="p-fluid formgrid grid justify-content-around">
            <div class="field col-12">
              <h2>General</h2>
            </div>

            <div class="field col-12">
              <label for="name">Company Name</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Company Name"
                  v-model="supplier.company_name"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.company_name.required"
              >
                Company name is required
              </div>
            </div>

            <div class="field col-12 sm:col-6">
              <label>Supplier Code</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="supplier.supplier_code"
                  placeHolder="Supplier Code"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.supplier_code.required"
              >
                Supplier code is required
              </div>
            </div>
            <div class="field col-12 sm:col-6 flex align-items-center">
              <div class="p-inputgroup flex align-items-center">
                <Checkbox
                  v-if="!isLoading"
                  id="binary"
                  class="pt-2"
                  v-model="supplier.active"
                  :binary="true"
                  :trueValue="1"
                  :falseValue="0"
                />
                <Skeleton
                  v-else
                  width="2rem"
                  height="2rem"
                  borderRadius="6px"
                ></Skeleton>
                <label
                  v-if="!isLoading"
                  class="font-light ml-3 pt-3"
                >Account Active</label>
                <Skeleton
                  v-else
                  class="ml-1 mt-1"
                  width="30%"
                  borderRadius="6px"
                ></Skeleton>
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Category</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="categories"
                  optionLabel="description"
                  optionValue="uid"
                  placeholder="Select Category"
                  class="input"
                  v-model="supplier.supplier_category_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.supplier_category_uid.required"
              >
                Category is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Telephone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="supplier.telephone_number"
                  placeHolder="Telephone"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.telephone_number.required"
              >
                Telephone number is required
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="!$v.supplier.telephone_number.integer"
              >
                Only enter numeric values
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="!$v.supplier.telephone_number.minLength"
              >
                Telephone number must be at least
                {{ $v.supplier.telephone_number.$params.minLength.min }} digits
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="!$v.supplier.telephone_number.maxLength"
              >
                Telephone number must not be more than
                {{ $v.supplier.telephone_number.$params.maxLength.max }} digits
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Email</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="supplier.email"
                  placeHolder="Email"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.email.required"
              >
                Email is required
              </div> -->
              <!-- <div
                class="error-validation-text pt-1"
                v-if="!$v.supplier.email.email"
              >
                Please enter a valid email
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Website</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="supplier.website"
                  placeHolder="Website"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.website.required"
              >
                Website is required
              </div> -->
            </div>

            <!-- <div class="col-12 md:col-6 lg:col-3">
              <div class="font-light mb-3">Physical Address</div>
              <div class="field col-12">
                <Textarea
                  id="Physical"
                  :autoResize="true"
                  placeHolder="Physical Address"
                  rows="5"
                  @input="
                    syncAddress();
                    isDirty = true;
                  "
                  v-model="account_details.address_physical"
                />
              </div>
            </div> -->
            <div class="field col-12 sm:col-6">
              <label
                clas="font-light mb-3"
                for="name"
              >Physical Address</label>
              <div>
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  placeholder="Physical Address"
                  :autoResize="true"
                  rows="5"
                  @input="
                    syncAddress();
                    isDirty = true;
                  "
                  v-model="supplier.physical_address"
                />
                <Skeleton
                  height="108px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>

            <div class="field col-12 sm:col-6">
              <div class="font-light mb-1 ml-2 flex align-items-center justify-content-between">
                Postal Address
                <div class="flex align-items-center">
                  <Checkbox
                    id="same"
                    v-model="sameAddress"
                    :binary="true"
                  />
                  <label
                    for="same"
                    class="pl-2 text-sm"
                  >Same as Physical</label>
                </div>
              </div>
              <div class="field col-12">
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  placeholder="Postal Address"
                  :autoResize="true"
                  rows="5"
                  :disabled="sameAddress === true"
                  v-model="supplier.postal_address"
                />
                <Skeleton
                  height="108px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>

            <!-- <div class="field col-12 sm:col-6">
              <label for="name">Physical Address</label>
              <div>
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  :autoResize="true"
                  rows="5"
                  v-model="supplier.physical_address"
                />
                <Skeleton height="108px" borderRadius="6px" v-else />
              </div>             
            </div>

            <div class="field col-12 sm:col-6">
              <label>Postal Address</label>
              <div>
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  :autoResize="true"
                  rows="5"
                  v-model="supplier.postal_address"
                />
                <Skeleton height="108px" borderRadius="6px" v-else />
              </div>
            </div> -->

            <Divider class="m-2 mb-5"></Divider>
            <div class="field col-12">
              <h2>Financial</h2>
            </div>
            <div class="field col-12">
              <label>Company Name Display</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="supplier.display_name"
                  placeHolder="Company Name Display"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.display_name.required"
              >
                Name display is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Tax / VAT Number</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Tax Number"
                  v-model="supplier.tax_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Tax / VAT Rates (%)</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-if="!isLoading"
                  :minFractionDigits="2"
                  locale="en-US"
                  placeHolder="Tax / VAT Rates (%)"
                  v-model="supplier.tax_rate"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.tax_rate.required"
              >
                Tax rate is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Currency</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="currencies"
                  optionLabel="name"
                  optionValue="uid"
                  placeholder="Select Currency"
                  class="input"
                  v-model="supplier.currency_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.currency_uid.required"
              >
                Currency is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Purchase Order Required</label>
              <div class="p-inputgroup">
                <Checkbox
                  v-if="!isLoading"
                  class="pt-2"
                  id="binary"
                  v-model="supplier.supplier_purchase"
                  :binary="true"
                  trueValue="yes"
                  falseValue="no"
                />
                <Skeleton
                  v-else
                  width="2rem"
                  height="2rem"
                  borderRadius="6px"
                ></Skeleton>
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Payment Terms </label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="supplierTerms"
                  optionLabel="description"
                  optionValue="uid"
                  placeholder="Select Payment Terms"
                  class="input"
                  v-model="supplier.supplier_terms_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.supplier_terms_uid.required"
              >
                Currency is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Credit Limit</label>
              <div class="p-inputgroup">
                <span
                  v-if="!isLoading"
                  class="p-inputgroup-addon"
                >{{
                  currencySymbol
                }}</span>
                <InputNumber
                  v-if="!isLoading"
                  :minFractionDigits="2"
                  locale="en-US"
                  placeholder="Credit limit"
                  class="input"
                  v-model="supplier.supplier_credit"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.supplier.supplier_credit.required"
              >
                Credit Limit is required
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="$route.params.uid"
          class="col-12 md:col-4 ml-6"
        >
          <div class="ml-3">
            <h2>Contacts</h2>
          </div>
          <Divider class="m-2"></Divider>
          <template>
            <div
              v-for="contact in supplier.contacts"
              :key="contact.uid"
            >
              <Skeleton
                class="ml-3 my-3"
                width="50%"
                v-if="isLoading"
              ></Skeleton>
              <div
                @mouseleave="mouseleave"
                @mouseenter="mouseenter(contact.uid)"
                v-if="contact"
                style="height: 20px"
                class="ml-3 my-3 cursor-pointer flex justify-content-between"
              >
                <div class="flex align-items-center">
                  <span>
                    <Button
                      v-model="contact.is_primary"
                      icon="lar la-star i-20"
                      :class="
                        'p-button-sm p-button-rounded h-2rem w-2rem p-0 mr-3 cursor-pointer ' +
                        getPrimaryContactClass(contact.is_primary)
                      "
                      v-tooltip.bottom="{
                        value: 'Make primary contact',
                        disabled: contact.is_primary === 1,
                      }"
                      @click="makeContactPrimary(contact)"
                    />
                  </span>

                  <div @click="
                      $store.dispatch('setSliderView', 'ContactSlider');
                      $store.dispatch('setSelectedObject', {
                        contact: contact,
                        obj: supplier,
                      });
                    ">
                    {{ contact.name }} {{ contact.surname }}
                  </div>
                </div>

                <div v-if="displayAccessories === contact.uid">
                  <i
                    v-tooltip.bottom="{
                      value: 'At least one contact should remain',
                      disabled: supplier.contacts.length > 1,
                    }"
                    :class="
                      'las la-trash i-20 grey pr-1' +
                      (supplier.contacts.length === 1
                        ? 'disable-grey cursor-disable'
                        : 'grey')
                    "
                    @click="
                      supplier.contacts.length == 1
                        ? () => {}
                        : deletePrompt(
                            contact.name + ' ' + contact.surname,
                            '/v1/costtype/delete/supplier/contact/' +
                              contact.uid
                          )
                    "
                  />
                </div>
              </div>
              <Divider class="m-2"></Divider>
            </div>
          </template>
          <div>
            <Button
              label="New Contact"
              class="p-button-text p-button-secondary w-auto p-3"
              icon="las la-plus"
              @click="
                $store.dispatch('setSliderView', 'ContactSlider');
                $store.dispatch('setSelectedObject', {
                  obj: supplier,
                });
              "
            />
          </div>
        </div>
        <div
          v-else
          class="col-12 md:col-6"
        >
          <div class="field col-12 p-fluid formgrid grid">
            <h2 v-if="addContact == true">
              Contact
              <Checkbox
                class="ml-2"
                id="same"
                v-model="addContact"
                :binary="true"
              />
            </h2>
            <h2 v-if="addContact == false">
              Add a contact
              <Checkbox
                class="ml-2"
                id="same"
                v-model="addContact"
                :binary="true"
              />
            </h2>
          </div>
          <div
            v-if="addContact == true"
            class="p-fluid formgrid grid justify-content-around"
          >
            <div class="field col-12 sm:col-6">
              <label for="name">Title</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="contactTitles"
                  optionLabel="title"
                  optionValue="uid"
                  placeholder="Select Title"
                  class="input"
                  v-model="contact.contact_title_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="
                  submitted &&
                  !$v.contact.contact_title_uid.required
                "
              >
                Contact title is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6 flex align-items-center">
              <div class="p-inputgroup flex align-items-center">
                <AActiveSwitch
                  v-if="!isLoading"
                  v-model="contact.is_primary"
                />
                <Skeleton
                  v-else
                  width="4rem"
                  height="2rem"
                  borderRadius="16px"
                ></Skeleton>
                <span
                  v-if="!isLoading"
                  class="font-light ml-3"
                >Primary Contact</span>
                <Skeleton
                  v-else
                  class="ml-1"
                  width="30%"
                  borderRadius="6px"
                ></Skeleton>
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Name</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Name"
                  v-model="contact.name"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.name.required"
              >
                Name is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Surname</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Surname"
                  v-model="contact.surname"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.surname.required"
              >
                Surname is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Designation</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Designation"
                  v-model="contact.designation"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="
                  submitted && !$v.contact.designation.required
                "
              >
                Designation is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Department</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Department"
                  v-model="contact.department"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="
                  submitted && !$v.contact.department.required
                "
              >
                Department is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Email</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Email"
                  v-model="contact.email"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="!$v.contact.email.email"
              >
                Please enter a valid email
              </div> -->
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.email.required"
              >
                Email is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Cellphone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Cellphone number"
                  v-model="contact.cell_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Telephone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Cellphone number"
                  v-model="contact.tel_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Ext</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeHolder="Extension"
                  v-model="contact.extension"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-5">
        <div class="col-5">
          <Button
            type="submit"
            label="SAVE"
          />
        </div>
        <div class="flex align-items-center">
          <Button
            label="CANCEL"
            @click="
              $router.push('/suppliers');
              $store.dispatch('setSelectedObject', null);
            "
            class="p-button-text p-button-secondary"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import {
  state,
  fetchCategories,
  fetchSupplierCredits,
  fetchCurrency,
  fetchSupplier,
} from "../../../services/data_service.js";
export default {
  data() {
    return {
      isDirty: false,
      sameAddress: false,
      addContact: false,
      submitted: false,
      isLoading: null,
      loadingText: "Loading...",
      displayAccessories: false,
      supplier: {
        company_name: null,
        active: 1,
        display_name: null,
        supplier_code: null,
        telephone_number: null,
        website: null,
        physical_address: null,
        postal_address: null,
        tax_number: null,
        tax_rate: null,
        supplier_category_uid: null,
        supplier_purchase: "yes",
        supplier_credit: null,
        supplier_terms_uid: "cod",
        currency_uid: null,
        contacts: [{}, {}, {}, {}, {}],
      },
      contact: {
        contactDetails: {
          contact_title_uid: null,
          company_name: null,
          name: null,
          surname: null,
          is_primary: !this.$route.params.uid ? 1 : 0,
          designation: null,
          email: null,
          cell_number: null,
          tel_number: null,
          department: null,
          extension: null,
        },
      },
    };
  },
  validations: {
    supplier: {
      company_name: {
        required,
      },
      display_name: {
        required,
      },
      supplier_code: {
        required,
      },
      // supplier_category_uid: {
      //   required,
      // },
      // telephone_number: {
      //   integer,
      //   required,
      //   minLength: minLength(10),
      //   maxLength: maxLength(10),
      // },
      // email: {
      //   required,
      //   email,
      // },
      // website: {
      //   required,
      // },
      // physical_address: {
      //   required,
      // },
      // postal_address: {
      //   required,
      // },
      tax_rate: {
        required,
      },
      currency_uid: {
        required,
      },

      supplier_terms_uid: {
        required,
      },
      supplier_credit: {
        required,
      },
    },
    contact: {
      contactDetails: {
        // contact_title_uid: {
        //   required,
        // },
        name: {
          required,
        },
        // surname: {
        //   required,
        // },
        // designation: {
        //   required,
        // },
        // email: {
        //   required,
        //   email,
        // },
        // department: {
        //   required,
        // },
      },
    },
  },
  beforeMount() {
    state.supplier = null;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    if (!this.$route.params.uid) {
      this.supplier.contacts = [];
    }
    this.isLoading = false;
  },
  methods: {
    setSupplier(obj) {
      this.supplier = obj;
      this.supplier.accountActive = obj.active;
      this.supplier.tax_rate = parseFloat(obj.tax_rate);
      this.supplier.display_name = obj.display_name;
      this.supplier.supplier_category_uid = obj.supplier_category_uid;
      this.supplier.supplier_credit = parseFloat(obj.supplier_credit);
      this.supplier.supplier_purchase = obj.supplier_purchase;
      this.supplier.supplier_terms_uid = obj.supplier_terms;
      this.supplier.currency_uid = obj.currency_uid;
      this.supplier.contacts = obj.contacts;
    },
    async loadData() {
      var arr = [fetchSupplierCredits(), fetchCurrency(), fetchCategories()];

      if (this.$route.params.uid) {
        arr.push(fetchSupplier(this.$route.params.uid));
      }
      await Promise.all(arr);
    },
    mouseenter(uid) {
      this.displayAccessories = uid;
    },
    mouseleave() {
      this.displayAccessories = null;
    },
    makeContactPrimary(contact) {
      let contactInfo = { contact };
      if (contactInfo.contact.is_primary == 1) {
        return;
      } else {
        contactInfo.contact.is_primary = 1;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/costtype/update/supplier/contact/" +
              contact.uid,
            JSON.stringify(contactInfo),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: `${contactInfo.contact.name} is now the primary contact`,
              life: 3000,
            });
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    syncAddress() {
      if (this.sameAddress === true && this.supplier?.physical_address) {
        this.supplier.postal_address = this.supplier?.physical_address;
      }
    },
    submitHandler() {
      this.submitted = true;
      if (!this.$route.params.uid && this.addContact == true) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
        } else {
          if (!this.hasUid) {
            this.contact.company_name = this.supplier.company_name;
            this.supplier.contacts.push(this.contact);
          } else {
            this.contact = this.supplier.contacts[0];
          }
          this.isLoading = true;
          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/costtype/" +
                (!this.$route.params.uid ? "create/supplier" : "suppliers") +
                (!this.$route.params.uid ? "" : "/" + this.$route.params.uid),
              this.supplier,
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.$router.push("/suppliers");
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      } else if (!this.$route.params.uid && this.addContact == false) {
        this.$v.$touch();
        if (this.$v.supplier.$invalid == true) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
        } else {
          this.isLoading = true;
          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/costtype/" +
                (!this.$route.params.uid ? "create/supplier" : "suppliers") +
                (!this.$route.params.uid ? "" : "/" + this.$route.params.uid),
              this.supplier,
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.$router.push("/suppliers");
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      } else if (this.$route.params.uid) {
        this.$v.$touch();
        if (this.$v.supplier.$invalid == true) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
        } else {
          this.isLoading = true;

          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/costtype/" +
                this.$route.params.uid
                ? `/v1/costtype/update/supplier/${this.$route.params.uid}`
                : "create/supplier",
              this.supplier,
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.$router.push("/suppliers");
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      }
    },
    getPrimaryContactClass(t) {
      return t == 1 ? "is-primary-contact" : "not-primary-contact";
    },
  },

  computed: {
    currencySymbol() {
      var setCurrency = null;
      this.currencies?.map((currency) => {
        if (currency.uid == this.supplier.currency_uid) {
          setCurrency = currency.description;
        }
      });
      return setCurrency;
    },

    supplierCredits() {
      return state.supplierCredits;
    },

    supplierTerms() {
      return [
        {
          uid: "cod",
          description: "Cash on Delivery",
        },
        {
          uid: "other",
          description: "Other",
        },
      ];
    },
    currencies() {
      return state.currencies;
    },
    categories() {
      return state.categories;
    },
    stateSupplier() {
      return state.supplier;
    },
    contactTitles() {
      return [
        {
          uid: "mr",
          title: "Mr",
          description: "mr",
        },
        {
          uid: "mrs",
          title: "Mrs",
          description: "mrs",
        },
      ];
    },
    hasUid() {
      return this.$route.params.uid;
    },
  },
  watch: {
    sameAddress(n) {
      if (n === true) {
        this.syncAddress();
      }
    },
    stateSupplier(n, o) {
      if (n) {
        this.setSupplier(n);
      }
    },
  },
  metaInfo() {
    return {
      title: this.hasUid
        ? this.stateSupplier
          ? this.stateSupplier.name
          : "Supplier"
        : "New Supplier",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
};
</script>

<style lang="scss" scoped>
.is-primary-contact {
  background-color: white !important;
  border-color: white !important;
  color: #6dd230 !important;
}

.not-primary-contact {
  background-color: white !important;
  border-color: white !important;
  color: #778ca2 !important;
}

.not-primary-contact:hover {
  background-color: white !important;
  border-color: white !important;
  color: #6dd230 !important;
}
</style>