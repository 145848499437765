<template>
  <div class="grid">
    <div class="flex flex-wrap w-full justify-content-between align-items-center">
      <div class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center">
        <h1 class="p-3 mr-1">Reports</h1>
      </div>
    </div>

    <TabView
      class="col-12 p-tab-show-panel sticky-tabs"
      :activeIndex.sync="active"
    >
      <TabPanel header="Jobs">
        <ActiveJobs
          key="active"
          v-if="active === 0"
        />
      </TabPanel>
      <TabPanel header="Work Distribution">
        <WorkDistribution
          key="work-distribution"
          v-if="active === 1"
        />
      </TabPanel>

      <TabPanel header="Forecast">
        <Forecast
          key="forecast"
          v-if="active === 2"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
  
  <script>
import ActiveJobs from "./ActiveJobs.vue";
import WorkDistribution from "../components/WorkDistribution.vue";
import Forecast from "../components/Forecast.vue";

export default {
  name: "Clients",
  data() {
    return {
      active: 0,
    };
  },
  components: {
    ActiveJobs,
    WorkDistribution,
    Forecast,
  },
  metaInfo: {
    title: "Reports",
    meta: [
      { vmid: "description", name: "description", content: "Reports Page" },
    ],
  },
};
</script>
  
  <style>
/* Add any global styles here */
</style>
  