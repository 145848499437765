import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from "./store";
import { axiosInstance } from './helpers/axios'
import '@/assets/styles/fa.all.css'
import '@/assets/styles/line-awesome.css'

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@/assets/styles/app.css'
import '@/assets/styles/layout.scss'
import '@/assets/styles/theme.scss'

import Vuebar from 'vuebar';
Vue.use(Vuebar);

import RocketLoad from '@/application/Theme/components/RocketLoad';
Vue.component('RocketLoad', RocketLoad) 

import LoginLogo from './components/LoginLogo.vue';
Vue.component('LoginLogo', LoginLogo)

import ConnectXero from './components/ConnectXero.vue';
Vue.component('ConnectXero', ConnectXero)

// import Layout from '@/layouts/Layout';
// Vue.component('Layout', Layout)

import LayoutLogin from '@/layouts/LayoutLogin';
Vue.component('LayoutLogin', LayoutLogin)

import LayoutApp from '@/layouts/LayoutApp';
Vue.component('LayoutApp', LayoutApp)


import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue, { ripple: true });


import VueLodash from 'vue-lodash'
import groupBy from 'lodash/groupBy'
Vue.use(VueLodash, { lodash: { groupBy } })

import VueSkeletonLoader from 'skeleton-loader-vue';
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

import debouceError from './helpers/debounceError.vue'
Vue.prototype.$debouceError = debouceError;

import { GlobalMixin } from './mixins/globalMixin'
Vue.mixin(GlobalMixin)

import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import Chip from 'primevue/chip';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Editor from 'primevue/editor';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import FullCalendar from 'primevue/fullcalendar';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import ProfilePicture from '@/components/ProfilePicture'
import ProfilePicture2 from '@/components/ProfilePicture2'
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import Listbox from 'primevue/listbox';
import OrderList from 'primevue/orderlist';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Badge from 'primevue/badge';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
import ContextMenu from 'primevue/contextmenu';
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
import InputNumber from 'primevue/inputnumber';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Tag from 'primevue/tag';
import Slider from 'primevue/slider';
import ConfirmDialog from 'primevue/confirmdialog';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import Panel from 'primevue/panel';
import Paginator from 'primevue/paginator';
import Chart from 'primevue/chart';
 

Vue.use(ConfirmationService);

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);

import VueDraggable from 'vue-draggable'
Vue.use(VueDraggable)

import VTimers from 'v-timers'
Vue.use(VTimers)

import VueImg from 'v-img';
Vue.use(VueImg);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import FilterSearchButton from './components/FilterSearchButton.vue'
Vue.component('FilterSearchButton', FilterSearchButton);
import ATextarea from './components/ATextarea.vue'
Vue.component('ATextarea', ATextarea);
import ADropdown from './components/ADropdown.vue'
Vue.component('ADropdown', ADropdown);
import AInputSwitch from './components/AInputSwitch.vue'
Vue.component('AInputSwitch', AInputSwitch);
import AButtonAdd from './components/AButtonAdd.vue'
Vue.component('AButtonAdd', AButtonAdd);
import AProfilePictureUploader from './components/AProfilePictureUploader.vue'
Vue.component('AProfilePictureUploader', AProfilePictureUploader);
import AActiveSwitch from './components/AActiveSwitch'
Vue.component('AActiveSwitch', AActiveSwitch);
import TaskTag from './components/TaskTag'
Vue.component('TaskTag', TaskTag);
import Timeline from 'primevue/timeline';
Vue.component('Timeline', Timeline)
import ProgressAvatar from './components/ProgressAvatar.vue'
Vue.component('ProgressAvatar', ProgressAvatar);

Vue.component('OverlayPanel', OverlayPanel);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('FullCalendar', FullCalendar);
Vue.component('InputText', InputText);
Vue.component('InputMask', InputMask);
Vue.component('Password', Password);
Vue.component('Textarea', Textarea);
Vue.component('Button', Button);
Vue.component('Toast', Toast);
Vue.component('Card', Card);
Vue.component('Checkbox', Checkbox)
Vue.component('RadioButton', RadioButton)
Vue.component('InputSwitch', InputSwitch)
Vue.component('SelectButton', SelectButton)
Vue.component('Dropdown', Dropdown)
Vue.component('MultiSelect', MultiSelect)
Vue.component('Editor', Editor)
Vue.component('Calendar', Calendar)
Vue.component('Inplace', Inplace)
Vue.component('ProfilePicture', ProfilePicture)
Vue.component('ProfilePicture2', ProfilePicture2)
Vue.component('Divider', Divider)
Vue.component('FileUpload', FileUpload)
Vue.component('Listbox', Listbox)
Vue.component('OrderList', OrderList)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('ColumnGroup', ColumnGroup)
Vue.component('Row', Row)
Vue.component('DataView', DataView)
Vue.component('Dialog', Dialog)
Vue.component('Badge', Badge)
Vue.component('ContextMenu', ContextMenu)
Vue.component('Menu', Menu)
Vue.component('Sidebar', Sidebar)
Vue.component('InputNumber', InputNumber)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('Tag', Tag)
Vue.component('Slider', Slider)
Vue.component('Chip', Chip)
Vue.component('Skeleton', Skeleton)
Vue.component('Avatar', Avatar);
Vue.component('AvatarGroup', AvatarGroup);
Vue.component('Panel', Panel);
Vue.component('Paginator', Paginator);
Vue.component('Chart', Chart);



import TransitionExpand from 'vue-transition-expand'
Vue.use(TransitionExpand)

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://a077008b5517476cbcb4efa77d30c4ae@o1294995.ingest.sentry.io/6519949",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost:8081", "app.agencydesk.io", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)

import Ripple from 'primevue/ripple';
Vue.directive('ripple', Ripple);

Vue.use(require('vue-moment'));
Vue.use(money, { precision: 4 })

import money from 'v-money'

Vue.directive('badge', BadgeDirective);

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
  update: function (el) {
    Vue.nextTick(function () {
      el.focus();
    })
  }
})


Vue.directive('form-enter',{
  inserted: function (el) {
    el.addEventListener('keydown', (e) => {
      console.log(e)
      if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA' && e.target.type !== 'submit') {
        e.preventDefault();
        const focusableElements = el.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const currentIndex = Array.from(focusableElements).indexOf(e.target);
        const nextElement = focusableElements[currentIndex + 1] || focusableElements[0];
        nextElement.focus();
      }
    });
  }
})


Vue.directive('can', {
  inserted(el, binding, vnode, old) {
    if(!binding.value){
      return
    }

    const permissions = store.getters.permissions;

    var role = 'user'
    if(permissions == 1){
        role = 'admin'
    }

    if (role != binding.value) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
});

Vue.directive('can-click',{
  inserted(el, binding, vnode, old) {
    if(!binding.value){
      return
    }

    const permissions = store.getters.permissions;

    var role = 'user'
    if(permissions == 1){
        role = 'admin'
    }

    if (role != binding.value) {
      el.style.pointerEvents = 'none'
      el.style.opacity = '0.5'
    }
  }
})

import VueBus from 'vue-bus';
Vue.use(VueBus);

Vue.directive('full-height', {
  bind(el, binding) {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const distanceToTop = el.getBoundingClientRect().top;
      const footer = 47
      const height = windowHeight - distanceToTop - footer;
      el.style.height = `${height}px`;
    };
    window.addEventListener('resize', handleResize);
    el._handleResize = handleResize;
    Vue.nextTick(() => {
      handleResize();
    });
  },
  update(el, binding) {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const distanceToTop = el.getBoundingClientRect().top;
      const footer = 47
      const height = windowHeight - distanceToTop - footer;
      el.style.height = `${height}px`;
    };
    window.addEventListener('resize', handleResize);
    el._handleResize = handleResize;
    Vue.nextTick(() => {
      handleResize();
    });
  },
  unbind(el) {
    window.removeEventListener('resize', el._handleResize);
    delete el._handleResize;
  },
});

Vue.prototype.$store = store
Vue.prototype.$axios = axiosInstance

Vue.directive('tooltip', Tooltip);
Vue.config.productionTip = false

import Pusher from "pusher-js";
var pusher = new Pusher("1014659666422b1f8f48", { cluster: "eu" });
Vue.prototype.$pusher = pusher

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
