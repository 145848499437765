<template>
  <div align="left">
    <div class="card">
      <div class="flex justify-content-between pb-2">
        <div class="col flex mb-1">
          <i
            @click="closeEstimate"
            style="color: #778ca2"
            class="las text-20 la-angle-left pt-1 pr-1 bluehover cursor-pointer"
          />
          <h1 class="flex align-items-center mr-3 font-light">
            Estimate:&nbsp;
            <!-- Place holder for estimate number -->
            <h1 v-if="!isLoading">
              &nbsp;{{ stateInvoice?.invoice?.document_number }}
            </h1>
            <Skeleton
              width="100px"
              height="24px"
              borderRadius="6px"
              v-else
            />
            <span class="text-12 pl-4 mr-3"><span
                v-if="!isLoading"
                :style="getTagStyle() + ' borderRadius: 4px'"
                class="p-2 py-1 uppercase"
              >
                <!-- Place holer for estimate status -->
                {{ stateInvoice?.invoice?.status }}</span>
              <Skeleton
                width="52px"
                height="21px"
                borderRadius="4px"
                v-else
              />
            </span>
          </h1>
        </div>

        <div
          v-if="stateInvoice?.invoice?.status !== 'approved'"
          class="pr-2"
        >
          <Button
            v-if="!isLoading"
            :loading="isApproving"
            label="Activate Tasks"
            @click="approveEstimate"
            class="p-button-primary"
            :disabled="isApproving"
          />
        </div>
        <div>
          <Button
            v-if="!isLoading"
            :loading="downloading"
            class="p-button-secondary"
            @click.stop="getPdf(uid)"
            label="Download"
            icon="las la-cloud-download-alt"
          />
        </div>
        <div class="flex text-center align-items-center">
          <div class="ml-3">
            <Skeleton
              height="40px"
              width="80px"
              borderRadius="6px"
              v-if="isLoading"
            />
            <template v-else>
              <Button
                v-if="stateInvoice?.invoice?.status === 'issued'"
                class="p-button-warning"
                label="Issued"
                disabled
              />
            </template>
          </div>
        </div>
      </div>
      <Divider class="mt-0 mb-2"></Divider>
      <div class="pt-1">
        <div class="parent-div">
          <div class="p-fluid formgrid grid">
            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
              <div class="p-fluid formgrid grid justify-content-start p-2">
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">Date Created</label>
                  <!-- Placeholder for due date -->
                  <div v-if="!isLoading">
                    {{ formatDate(stateInvoice?.invoice.date_created) }}
                  </div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">Terms</label>
                  <!-- Placeholder for terms -->
                  <div v-if="!isLoading">Cash on Delivery</div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
                <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                  <label class="font-light">PO Number</label>
                  <!-- Placeholder for terms -->
                  <div v-if="!isLoading">N/A</div>
                  <Skeleton
                    height="18px"
                    borderRadius="6px"
                    v-else
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="field col">
                <label class="font-light">Description
                  <template v-if="!isEditDesc">
                    <i
                      @click.stop="isEditDesc = true"
                      class="las hover-show la-pen i-18 ml-1 cursor-pointer grey"
                    /></template>

                  <template v-else>
                    <i
                      @click.stop="isEditDesc = false"
                      class="las la-check i-18 ml-1 cursor-pointer grey"
                    />
                    <i
                      @click.stop="isEditDesc = false"
                      class="las la-times i-18 ml-2 cursor-pointer grey"
                    />
                  </template>
                </label>

                <div v-if="!isLoading">

                  <div
                    v-if="!isEditDesc"
                    v-html="stateInvoice?.invoice?.description"
                  >

                  </div>

                  <Textarea
                    v-else
                    ref="editor"
                    v-model="ceDescription"
                    :autoResize="true"
                  ></Textarea>

                </div>
                <Skeleton
                  height="18px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
          </div>
          <div
            class="flex justify-content-between align-items-center my-3"
            style="font-size: 18px"
          >
            <div class="">
              <Skeleton
                v-if="isLoading"
                height="22px"
                borderRadius="6px"
                width="100px"
                class="ml-1 mt-2"
              ></Skeleton>
              <p
                v-else
                class="p-t-12 p-2"
                style="font-size: 18px"
              >

                <span v-if="!isEditTitle"> {{ stateInvoice?.invoice?.name ??  'Tasks & Costs'}}</span>

                <InputText
                  type="text"
                  v-else
                  v-model="ceName"
                  class="p-column-filter"
                  placeholder="Cost Estimate Name"
                />
                <template v-if="!isEditTitle">
                  <i
                    v-if="stateInvoice?.invoice?.name"
                    @click.stop="isEditTitle =true"
                    class="las hover-show la-pen i-18 ml-1 cursor-pointer grey"
                  /></template>

                <template v-else>
                  <i
                    @click.stop="isEditTitle = false"
                    class="las la-check i-18 ml-1 cursor-pointer grey"
                  />
                  <i
                    @click.stop="isEditTitle = false"
                    class="las la-times i-18 ml-2 cursor-pointer grey"
                  />
                </template>
              </p>

            </div>
          </div>
        </div>
        <div v-if="isLoading">
          <Skeleton
            class="mb-3"
            v-for="i in 4"
            :key="i"
            height="36px"
            borderRadius="6px"
          />
        </div>

        <template v-else>
          <InvoiceTaskCost
            :groups="typeItems"
            :setCurrencySymbol="setCurrencySymbol"
            :jobId="jobId"
            :canSelect="false"
          ></InvoiceTaskCost>
        </template>
      </div>

      <TotalTableFooter
        :subTotal="stateInvoice?.totals?.sub"
        :taxRate="stateInvoice?.totals?.tax"
        :profitMargin="stateInvoice?.totals?.margin"
        :total="stateInvoice?.totals?.total"
        :currencySymbol="job?.client?.currency?.description"
        v-if="stateInvoice?.totals"
        :canSelect="true"
      ></TotalTableFooter>
    </div>
    <Dialog
      header="Select items"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;max-width:1400px"
      :visible.sync="displayAddItems"
      :modal="true"
      :closable="true"
    >
      <template v-if="jobItems">
        <InvoiceTaskCost
          :groups="jobItems"
          :setCurrencySymbol="setCurrencySymbol"
          @dismiss="displayAddItems = false"
          :jobId="jobId"
          :dismissableMask="true"
          notInvoiced
        ></InvoiceTaskCost>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {
  state,
  fetchInvoice,
  fetchJobItems,
} from "../../../services/data_service.js";
import TotalTableFooter from "@/application/Finance/components/TotalTableFooter.vue";
import InvoiceTaskCost from "@/application/Finance/components/InvoiceTaskCost.vue";

export default {
  data() {
    return {
      displayAddItems: false,
      ceDescription: "",
      ceName: "",
      isEditDesc: false,
      isEditTitle: false,
      isLoading: false,
      isIssueLoading: false,
      invoice: null,
      items: null,
      setCurrencySymbol: null,
      taxRate: null,
      profitMargin: null,
      status: null,
      isApproving: false,
      downloading: false,
    };
  },

  beforeMount() {
    state.invoice = null;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    this.ceDescription = this.stateInvoice?.invoice?.description;
    this.ceName = this.stateInvoice?.invoice?.name;
    this.isLoading = false;
  },
  computed: {
    uid() {
      return this.$route.params.estimate_uid;
    },
    jobId() {
      return this.$route.params.uid;
    },
    stateInvoice() {
      return state.invoice;
    },
    subTotal() {
      var s = 0;
      this.stateInvoice?.items.forEach((g) => {
        g.tasks.forEach((t) => {
          s += parseFloat(t.total);
        });
        g.costs.forEach((t) => {
          s += parseFloat(t.total);
        });
      });
      return s;
    },
    jobItems() {
      return state.jobItems;
    },
    job() {
      return state.job;
    },
    typeItems() {
      return this.stateInvoice?.items?.map((item) => {
        const tasksWithType = (item.tasks || []).map((task) => ({
          ...task,
          type: "task",
        }));
        const costsWithType = (item.costs || []).map((cost) => ({
          ...cost,
          type: "cost",
        }));

        return {
          ...item,
          tasks: tasksWithType,
          costs: costsWithType,
        };
      });
    },
  },
  methods: {
    async getPdf(id) {
      this.downloading = true;
      await this.downloadPdf(
        process.env.VUE_APP_ROOT_API + `/v1/finance/download/${id}`
      );
      setTimeout(() => {
        this.downloading = false;
      }, 3000);
    },
    approveEstimate() {
      this.isApproving = true;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/v1/finance/estimate/approve/${this.stateInvoice.invoice.uid}`
        )
        .then(async (response) => {
          this.$toast.add({
            severity: "success",
            summary: `${this.stateInvoice?.invoice?.document_number} has been approved`,
            life: 3000,
          });
          this.$bus.emit("refreshData");

          this.isApproving = false;
        })
        .catch((error) => {
          this.isApproving = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    formatDate(date) {
      return this.$moment(date).format("DD MMM YY");
    },
    getTagStyle() {
      switch (this.stateInvoice?.invoice?.status) {
        case "draft":
          return "background: #B9C5D0 !important;color: #252631 !important;";
        case "issued":
          return "background: #f59e0b !important;color: #ffffff !important;";
        case "approved":
          return "background: rgb(109, 210, 48)!important;color: #ffffff !important;";
        default:
          return "background: #F8FAFB !important;color: #778CA2 !important;";
      }
    },
    async loadData() {
      await Promise.all([
        fetchInvoice(this.uid),
        fetchJobItems(this.$route.params.uid, this.uid),
      ]);
    },
    closeEstimate() {
      this.$router.push({ name: "job--estimates" });
    },
    addType(items) {
      items?.forEach((i) => {
        i.tasks?.forEach((t) => {
          t.type = "task";
        });
        i.costs?.forEach((c) => {
          c.type = "cost";
        });
      });
      return items;
    },
  },
  watch: {},

  metaInfo() {
    return {
      title: "Estimate",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
  name: "Estimate",
  components: { TotalTableFooter, InvoiceTaskCost },
};
</script>
<style>
.bluehover:hover {
  color: #4d7cfe !important;
}
</style>