var render = function render(){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"flex header task-header pr-2 cursor-pointer",style:('background-color: #f8fafb;' +
    (!_vm.showUnplanned && _vm.hasPlannedTasks
      ? 'border-bottom: 4px solid #f2f4f6;'
      : '')),on:{"click":_vm.handleClick}},[_c('div',{staticClass:"col-1"}),_vm._m(0),_c('div',{staticClass:"col inline-flex align-self-center justify-content-between"},[_c('div',{staticClass:"col p-0"},[_c('span',{class:'p-badge p-component w-3rem ' +
          (_vm.showUnplanned ? 'hidden-unplanned' : 'unplanned-hours')},[_vm._v(_vm._s(!_vm.showUnplanned ? _vm.totalUnplannedHours ? _vm.totalUnplannedHours : "00:00" : "0")+" ")])]),_c('div',{staticClass:"absolute right-0 mr-3 align-self-center"},[_c('i',{class:'cursor-pointer align-self-center la la-angle-up icon font-smaller ' +
          (_vm.showUnplanned ? ' icon-collapsed' : ' icon-expanded')})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7 flex vertical-middle p-02"},[_c('div',{staticClass:"font-light align-self-center flex font-smaller"},[_c('span',[_vm._v("Unplanned Tasks")])])])
}]

export { render, staticRenderFns }