<template>
  <div class="flex align-items-center justify-content-center">

    <div
      v-if="cellValue?.includes('|')"
      class="flex flex-column"
    >
      <span>{{ cellValue.split('|')[0] }}</span>
      <span style="margin-top:1px">{{ cellValue.split('|')[1] }}</span>
    </div>

    <div v-else>{{ cellValue }}</div>

    <div
      v-if="hasValue && indexDate >= todayDate"
      class="scheduled-cell"
      :class="{
                    'adjacent-left': hasValueToLeft,
                    'adjacent-right': hasValueToRight,
                    'is-today': index === today,
                }"
    >
      <span
        v-if="task.date_due.date === index"
        class="due_date_cell_circle"
      > </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
    prevDay: {
      type: String,
    },
    index: {
      type: String,
      required: true,
    },
    nextDay: {
      type: String,
    },
    today: {
      type: String,
      required: true,
    },
    days: {
      type: Object,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    isWorkDay: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    indexDate() {
      return new Date(this.index);
    },
    todayDate() {
      return new Date(this.today);
    },
    hasValueToLeft() {
      const leftCellValue = this.getCellValue(this.task, this.prevDay);
      return !!leftCellValue && leftCellValue !== "X";
    },
    hasValueToRight() {
      const rightCellValue = this.getCellValue(this.task, this.nextDay);
      return !!rightCellValue && rightCellValue !== "X";
    },
    cellValue() {
      return this.getCellValue(this.task, this.index);
    },
    hasValue() {
      return (
        this.cellValue &&
        this.cellValue !== "X" &&
        !this.cellValue.includes("|")
      );
    },
    splitValue() {
      return this.value?.split("|");
    },
  },
  methods: {
    getCellValue(task, index) {
      const r = task.resource_plan?.find((a) => a.date === index);
      if (r) {
        if (!r.time) return "hg";
        return index < this.today
          ? `${this.secToHM(r.minutes_worked * 60)}|${r.time}`
          : r.time_worked && r.time_worked != "00:00"
          ? `${r.time_worked}|${r.time}`
          : r.time;
      }
      return this.isWorkDay ? null : "X";
    },
  },
};
</script>

<style scoped>
.scheduled-cell {
  position: absolute;
  bottom: 0;
  left: -1px;
  width: calc(100% + 2px);
  height: 30%;
  background-color: #6dd230;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-radius: 0.4rem;
}

.scheduled-cell.adjacent-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.scheduled-cell.adjacent-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.scheduled-cell.is-today {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  left: 0.5px;
  width: 101%;
}

.due_date_cell_circle {
  position: absolute;
  top: 2.5px;
  right: 5px;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border-radius: 50%;
}

.pt-3px {
  padding-top: 3px;
}
</style>