<template>
  <InputSwitch
    style="vertical-align: middle"
    :value="inputVal == '1'"
    @change="
      inputVal = updateInputSwitch(inputVal);
      $emit('change');
    "
    class="green"
    :disabled="disabled"
  />
</template>


<script>
export default {
  props: {
    value: {
      default: "0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value.toString();
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>