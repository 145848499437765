<template>
  <tr
    v-if="expand && totalUnplannedHours !== '00:00'"
    class="bg-f8fafb"
  >
    <TaskScheduleUnplannedTasks
      :totalUnplannedHours="totalUnplannedHours"
      :userTasks="user.tasks"
      :user="user"
      @toggleExpand="handleTaskToggle(user)"
      :workingHours="user.working_hours"
      :showUnplanned="showUnplannedUsers.includes(user.uid)"
      :hasPlannedTasks="hasPlannedTasks"
    ></TaskScheduleUnplannedTasks>

    <td
      v-for="(i, index) in days"
      :key="index + '-up'"
      :style="
        index == today
          ? 'border-left-style: solid; border-left-color: #4D7CFE;border-left-width:0.15rem'
          : ''
      "
    ></td>
  </tr>
</template>
<script>
import TaskScheduleUnplannedTasks from "./TaskScheduleUnplannedTasks.vue";
export default {
  components: {
    TaskScheduleUnplannedTasks,
  },
  name: "TaskScheduleUnplannedRow",
  props: {
    user: {
      type: Object,
    },
    days: {
      type: Object,
    },
    today: {
      type: String,
    },
    showUnplannedUsers: {
      type: Array,
    },
  },
  methods: {
    handleTaskToggle(user) {
      this.$emit("taskToggle", user);
    },
  },
  computed: {
    expand() {
      return !this.$store.getters.taskScheduleSettings.userCollapse.includes(
        this.user.uid
      );
    },
    userTasks() {
      return this.user.tasks;
    },

    hasPlannedTasks() {
      return this.userTasks.some((task) => task.hasPlanned === true);
    },

    totalUnplannedHours() {
      var s = this.userTasks.reduce((acc, task) => {
        if (task && task.hours_unplanned && !task.hours_planned) {
          const [hours, minutes] = task.hours_unplanned.split(":");
          acc += parseInt(hours) + parseInt(minutes) / 60;
        }
        return acc;
      }, 0);

      //convert s to hh:mm
      var hours = Math.floor(s);
      var minutes = Math.round((s - hours) * 60);
      if (minutes == 60) {
        hours++;
        minutes = 0;
      }
      s =
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0");
      return s;
    },
  },
};
</script>
